<template>
	<div class="sales-quote-table position-relative w-100 h-100 px-4 py-3 d-flex flex-column overflow-hidden">
		<table-summary title="Sales Quote" filter="quoteNo" sort="issuedAt"
		               v-bind:order="-1"
		               v-bind:fields="fields"
		               v-bind:data-array="salesQuotations"
		               v-on:add-new="addQuote"
		               v-on:row-clicked="navigate($event)"></table-summary>
	</div>
</template>

<script>
import TableSummary from "@/components/functional/table/TableSummary";
import { format } from "date-fns"

export default {
	name: "SalesQuoteTable",
	
	components: {
		TableSummary,
	},
	
	data() {
		return {
			fields: [
				{ display: "Quote No.", key: "quoteNo", classBinding: ["f-2"] },
				{ display: "Client", key: "client", classBinding: ["f-5"] },
				{ display: "Items", key: "items", classBinding: [] },
				{ display: "Currency", key: "currency", classBinding: [] },
				{ display: "Total", key: "totalAmount", classBinding: ["f-2"] },
				{ display: "Issued At", key: "issuedAt", classBinding: [] },
				{ display: "Issued By", key: "issuedBy", classBinding: ["f-2"] },
			],
		}
	},
	
	computed: {
		salesQuotations() {
			return this.$store.state.contents.salesQuotations.map(quote => {
				const obj = {...quote};
				const client = this.$store.state.contents.clients.find(({ _id }) => _id === quote.client);
				const issuedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === quote.issuedBy);
				obj.client = client ? `${client.name} (${client.clientNo})` : "N/A";
				obj.issuedBy = issuedBy ? `${issuedBy.preferredName} ${issuedBy.lastName}` : "N/A";
				obj.issuedAt = quote.issuedAt ? format(new Date(quote.issuedAt), "yyyy-MM-dd") : "N/A";
				obj.items = quote.items.length; // type Number
				return obj;
			});
		}
	},
	
	methods: {
		async addQuote() {
			await this.$router.push("/sales/quotation/add");
		},
		
		async navigate(dataObj) {
			await this.$router.push(`/sales/quotation/${dataObj._id}`);
		}
	}
}
</script>

<style lang="scss" scoped>

</style>